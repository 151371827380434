import React from "react";
import "./business.css";
import mobileBg from "../../../images/packages/mobile-dulani-card-cover.svg";

interface HeadingContent {
  subheading: string;
  heading?: string;
  backgroundImage?: string;
  description?: string;
  imgSrc?: string;
  hideOnMobile?: boolean;
}

const HeadingComponent: React.FC<HeadingContent> = ({
  subheading,
  heading,
  description,
  imgSrc,
  hideOnMobile,
}) => {
  return (
    <div className="hero-headings">
      <p className={`hero-subheading ${hideOnMobile && "hide-on-mobile"}`}>
        {subheading}
      </p>
      <p className="heading"> {heading}</p>
      {imgSrc && (
        <div className={`banner  show-on-mobile`}>
          <img className="banner-image-hero" src={mobileBg} alt="" />
        </div>
      )}
      <p style={{ marginTop: "10px" }} className="description">
        {description}
      </p>
      {imgSrc && (
        <div className={`banner hide-on-mobile`}>
          <img className="banner-image-hero" src={imgSrc} alt="" />
        </div>
      )}
    </div>
  );
};

export default HeadingComponent;
